import { template as template_737e6f6220ef4fbdbf93551597ab0c78 } from "@ember/template-compiler";
const WelcomeHeader = template_737e6f6220ef4fbdbf93551597ab0c78(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
