import { template as template_2ed6adf1d1654cf68be57382d4515855 } from "@ember/template-compiler";
const FKText = template_2ed6adf1d1654cf68be57382d4515855(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
