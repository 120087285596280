import { template as template_ef613f1262cb4145a6c116382b361a3c } from "@ember/template-compiler";
const FKLabel = template_ef613f1262cb4145a6c116382b361a3c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
