import { template as template_39ab1184abad4d0e8e08552ac7aa0de0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_39ab1184abad4d0e8e08552ac7aa0de0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
